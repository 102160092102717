import React from 'react';
import { Link } from 'react-router-dom';


const Terms = () => {
  return (
    <div className="content">
        <div className="tirc-company">
            <Link to="/">
                <img src="/tirc.png" alt="Company Logo" className="tirc-logo" />
            </Link>
            <h1>Terms and Conditions</h1>
            <p className="slogan">Last updated: September 20, 2024</p>
            <hr style={{marginTop: "50px", marginBottom: "50px"}} />
            <div className='terms'>
                <p>Welcome to The Infinite Rise Company L.L.C. (TIRC)'s website! These terms and conditions outline the rules and regulations for the use of our website, located at http://www.theinfiniterisecompany.com. By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions.</p>

                <h2>1. Acceptance of Terms</h2>
                <p>By accessing or using this website, you accept and agree to these Terms and Conditions, including any amendments that may be made from time to time. If you do not agree with these Terms and Conditions, please do not use the website.</p>

                <h2>2. Company Information</h2>
                <p>TIRC is a holding company with interests in various subsidiary businesses. This website is intended to provide general information about the company, its portfolio, and its activities. The information provided is for informational purposes only and should not be construed as professional advice or an offer of any kind.</p>

                <h2>3. Content and Intellectual Property</h2>
                <p>You may view and print materials from this website for personal, non-commercial use only. Modification, reproduction, distribution, or public display of any content from this website without prior written permission from TIRC is strictly prohibited.</p>

                <h2>4. Use of the Website</h2>
                <p>You agree to use this website only for lawful purposes and in a manner that does not infringe upon the rights of, or restrict the use and enjoyment of, this website by any third party.</p>
                <ul>
                    <li style={{textAlign: "left"}}>You must not misuse this website by introducing viruses, trojans, or other malicious or harmful software.</li>
                    <li style={{textAlign: "left"}}>You must not attempt to gain unauthorized access to this website, its server, or any database connected to this website.</li>
                </ul>
                <p>TIRC reserves the right to restrict or terminate your access to the website if we believe you are violating these Terms and Conditions or engaging in unlawful or harmful behavior.</p>

                <h2>5. Website Content and Accuracy</h2>
                <p>While TIRC takes every reasonable effort to ensure that the information on this website is accurate and up-to-date, we do not make any warranties, express or implied, regarding the accuracy, completeness, or currency of the information.</p>

                <h2>6. Limitation of Liability</h2>
                <p>TIRC shall not be liable for any direct, indirect, incidental, special, or consequential damages that result from the use of, or inability to use, the website. This includes, but is not limited to, reliance on any information obtained from the website, mistakes, omissions, interruptions, deletion of files, or delays in operation.</p>
                <p>We will not be liable for any loss or damage caused by viruses or other technologically harmful material that may infect your computer equipment, software, data, or other proprietary material due to your use of this website or your downloading of any material posted on it.</p>

                <h2>7. Links to Subsidiary Websites</h2>
                <p>This website may contain links to websites owned and operated by subsidiaries of TIRC. While these links are provided for your convenience and we maintain close ties with these entities, TIRC does not assume responsibility for the content, accuracy, or practices of any external sites, including those of our subsidiaries.</p>

                <h2>8. Contact Information</h2>
                <p>If you wish to contact us, please email us at info@theemphasiselevated.com. Please note that this is the only contact method currently available through our website, and no online forms are provided.</p>

                <h2>9. Privacy Policy</h2>
                <p>TIRC respects your privacy. Although we do not collect personal data through forms on this website, any personal information you voluntarily provide to us (e.g., via email) will be handled in accordance with our Privacy Policy, which outlines how we collect, use, and protect your information.</p>
                <p>By using this website, you consent to the collection and use of your information as described in our Privacy Policy.</p>

                <h2>10. Changes to the Terms and Conditions</h2>
                <p>TIRC reserves the right to modify these Terms and Conditions at any time without prior notice. Any changes will be posted on this page, and continued use of the website will signify your acceptance of the updated Terms and Conditions.</p>

                <h2>11. Governing Law</h2>
                <p>These Terms and Conditions are governed by and construed in accordance with the laws of the State of Mississippi, and any disputes arising from or related to the use of this website shall be subject to the exclusive jurisdiction of the courts in the State of Mississippi.</p>

                <h2>12. Indemnification</h2>
                <p>You agree to indemnify, defend, and hold harmless TIRC, its directors, employees, and affiliates, from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of your use of the website, your violation of these Terms and Conditions, or your infringement of any intellectual property or other rights of any person or entity.</p>

                <h2>13. Severability</h2>
                <p>If any provision of these Terms and Conditions is found to be invalid or unenforceable under applicable law, such provision shall be removed, and the remaining provisions shall continue in full force and effect.</p>

                <h2>14. Termination</h2>
                <p>TIRC reserves the right to terminate your access to the website without notice, for any reason, including but not limited to a violation of these Terms and Conditions.</p>

            </div>
        </div>
    </div>
  );
};

export default Terms;