import React from 'react';
import { Link } from 'react-router-dom';


const eWordArray = ["exceptionally", "efficiently", "excellently", "expertly", "effectively", "ecstatically", "elegantly", "energetically", "enthusiastically", "exquisitely", "experimentally", "exhaustively", "expeditiously", "exuberantly", "eagerly", "emotionally", "endlessly", "enduringly", "energizingly", "entertainingly"]
function Home() {
  return (
    <div className="content">
        <div className="tirc-company">
            <Link to="/">
                <img src="/tirc.png" alt="Company Logo" className="tirc-logo" />
            </Link>
            <h1>
                <span className="changing-words">
                    {
                        eWordArray.map((word, index) => (
                            <span key={index} style={{ animationDelay: `${index * 4}s` }}>{`working ${word}`}</span>
                        ))
                    }
                </span>
            </h1>
            <p className="slogan">Innovation, Reimagined.<br />Empowering the Infinite You.</p>
            <hr />
            <p className="description">We are living in a time of unprecedented excitement and innovation, where the possibilities are endless and the opportunities are vast. We are on the cusp of a technological revolution that will change the world as we know it, and we are thrilled to be a part of it. From the rise of automation and AI, to the development of new technologies that will make things easier and more enjoyable, we are committed to being at the forefront of this revolution. We see a future where technology is not just a tool, but a partner in journey, helping us to learn and grow in ways that were previously unimaginable. And we are committed to being a part of it. Our mission is to bring the best of comfort, excitement, and innovation together, making every moment more inspiring and enjoyable. </p>
        </div>
        <div style={{ marginTop: "-20px", alignSelf: "center"}}>
            <Link to="/"><img src="/THE3.png" alt="Company Logo" style={{height: "300px", padding: "20px"}} /></Link>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <div className="tirc-option" style={{width: "250px", border: "1px solid #029fe3", borderRight: "none", padding: "20px", boxShadow: "11px 11px 5px #2a2b72"}}>
                <h2 className="opt-desc" style={{color: "lightyellow"}}><i>Store (coming soon)</i></h2>
                <p className="opt-desc">Experience the most exclusive and exciting buys in tech today! We accumulate a selection of hottest new gadgets!</p>
            </div>
            <div className="tirc-option" style={{width: "250px", border: "1px solid #029fe3", borderLeft: "none", padding: "20px", marginLeft: "-3px", boxShadow: "11px 11px 5px #2a2b72"}}>
                <h2 className="opt-desc" style={{color: "lightyellow"}}><i>Blog (coming soon)</i></h2>
                <p className="opt-desc">Read the latest updates on our products, services and vision for the future of technology and innovation.</p>
            </div>
        </div>
    </div>
  );
}

export default Home;