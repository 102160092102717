import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div className="content">
        <div className="tirc-company">
            <Link to="/">
                <img src="/tirc.png" alt="Company Logo" className="tirc-logo" />
            </Link>
            <h1>About Us</h1>
            <hr />
            <div className="aboutus">
                <p>Welcome to The Infinite Rise Company L.L.C., where we are harnessing the power of innovation to create a world of possibilities. We believe that the current era offers unparalleled opportunities to explore, learn, and apply technological advancements in ways that elevate experiences. In the business and communities, we are also known as The Emphasis Elevated.</p>

                <p>With Artificial Intelligence (AI) and Automation leading the charge, we are on the cusp of a revolution that will bring unprecedented convenience, efficiency, and excitement. We are passionate about harnessing the potential of AI and Automation to create a future where the mundane tasks are automated, freeing us up to pursue our passions and spend time on the things that truly matter on this exciting journey.</p>

                <p>A keen eye on technological advancements has always unlocked new levels of innovation and excitement. We at the forefront of this resolution, reimagining the boundaries of what is possible and exploring new ways to apply objectives to experience expansive possibilities. We appreciate you, as we embark on this exciting journey and discover the infinite possibilities that await us.</p>
            </div>
        </div>
    </div>
  );
};

export default About;