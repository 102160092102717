import React from 'react';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div className="content">
        <div className="tirc-company">
            <Link to="/">
                <img src="/tirc.png" alt="Company Logo" className="tirc-logo" />
            </Link>
            <h1>Contact Us</h1>
            <hr />
            <p>In the our communities and business, we go by The Emphasis Elevated. <br/>We're always up for a chat. Reach out to us anytime for questions, feedback, or support!</p>

            <p>You can contact us via email at:</p>

            <p><a style={{color: "#db8700"}} href="mailto:info@theemphasiselevated.com">info@theemphasiselevated.com</a></p>

            <p>Thanks for visiting!</p>
        </div>
    </div>
  );
};

export default Contact;